@import '~antd/dist/antd.css';

.site-layout-content {
    padding: 24px;
    background: #fff;
}
.search_side.ant-space {
    width: 100%;
}
.search_side.ant-space .ant-space-item:first-child {
    width: 100%;
}
